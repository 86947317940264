import React, {useState} from 'react';
import PropTypes from 'prop-types';

import lang from '../../../../../lang/pages/aktionsteilnahmen.lang';
import Image from '../../../../components/Image';
import magnifier from '../../../../../assets/images/aktionen/img-lupe-mit-hintergrund.png';
import Lightbox from '../../../../components/Lightbox';
import {makeUrl} from '../../../../../model/backend-api';

export default function ImageLightbox({imageSrc, user}) {
    const [isOpen, setIsOpen] = useState(false);
    const lightboxToggle = () => setIsOpen(!isOpen);
    return (
        <>
            <Image
                width="40px"
                height="40px"
                alt={lang.submissionImage.thumbnailAlt}
                src={magnifier}
                onClick={lightboxToggle}
            />
            <Lightbox isOpen={isOpen} onClose={lightboxToggle}>
                <Image
                    width="100%"
                    src={makeUrl(imageSrc)}
                    alt={lang.submissionImage.lightboxImageAlt(user)}
                    responsive
                />
            </Lightbox>
        </>
    );
}

ImageLightbox.propTypes = {
    imageSrc: PropTypes.string,
    user: PropTypes.string,
};
