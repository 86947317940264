import React from 'react';

export default {
    headline: (
        <i>
            Dein <strong>Aktionenarchiv</strong>
        </i>
    ),
    paragraph:
        'Du möchtest wissen, wem Du bereits Punkte vergeben hast, aber auch wann und wofür? In Deinem Aktionenarchiv kannst Du das ganz einfach zurückverfolgen.',
};
